import React from "react"
import {Box, Typography} from "@material-ui/core";
import {SinglePage} from "../components/scaffold";

export default function App() {
  return (
    <SinglePage showPollButton>
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Sorry &ndash; diese Seite existiert nicht.
        </Typography>
      </Box>
    </SinglePage>
  );
}
